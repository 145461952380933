import { ethers } from 'ethers';
import { Notification } from 'components';
import { NotificationTypes } from 'constants/notificationTypes';

const CHAIN_ID = Number(process.env.REACT_APP_CHAIN_ID);
const MINIMUM_BALANCE = ethers.utils.parseEther('0.01');
const SUPPORTED_CHAIN_IDS = [137, 80001, CHAIN_ID];

interface ValidateWalletParams {
  web3Provider: ethers.providers.Web3Provider | null;
  isConnected: boolean;
}

const notifyError = (message: string, description: string) => {
  Notification({
    notificationType: NotificationTypes.Error,
    message,
    description,
  });
};

const checkConnection = (web3Provider: ethers.providers.Web3Provider | null, isConnected: boolean) => {
  if (!isConnected || !web3Provider) {
    notifyError('Wallet Not Connected', 'Please connect your wallet to continue.');
    throw new Error('Wallet is not connected.');
  }
};

const checkAddress = async (signer: ethers.providers.JsonRpcSigner): Promise<string> => {
  try {
    return await signer.getAddress();
  } catch {
    notifyError('Wallet Address Missing', 'Unable to retrieve the wallet address.');
    throw new Error('Wallet address is missing.');
  }
};

const checkBalance = async (web3Provider: ethers.providers.Web3Provider, address: string) => {
  const balance = await web3Provider.getBalance(address);
  if (balance.lt(MINIMUM_BALANCE)) {
    notifyError('Insufficient Balance', 'Your wallet does not have enough funds to proceed.');
    throw new Error('Insufficient wallet balance.');
  }
};

const checkNetwork = async (web3Provider: ethers.providers.Web3Provider) => {
  try {
    const network = await Promise.race([
      web3Provider.getNetwork(),
      new Promise<never>((_, reject) => setTimeout(() => reject(new Error('Network Timeout')), 5000)),
    ]);

    if (!SUPPORTED_CHAIN_IDS.includes(network.chainId)) {
      notifyError('Unsupported Network', `The current network (Chain ID: ${network.chainId}) is not supported.`);
      throw new Error(`Unsupported network. Chain ID: ${network.chainId}`);
    }
  } catch (error) {
    notifyError('Network Timeout', 'Network validation took too long. Please try again.');
    throw new Error('Network Timeout');
  }
};

const checkBlockNumber = async (web3Provider: ethers.providers.Web3Provider) => {
  const blockNumber = await web3Provider.getBlockNumber();
  if (!blockNumber) {
    notifyError('Network Unavailable', 'Unable to access the network. Please check your connection.');
    throw new Error('Network is unavailable.');
  }
};

export const getValidatedSigner = async ({
  web3Provider,
  isConnected,
}: ValidateWalletParams): Promise<ethers.providers.JsonRpcSigner> => {
  checkConnection(web3Provider, isConnected);

  const signer = web3Provider!.getSigner();
  const address = await checkAddress(signer);

  await checkBalance(web3Provider!, address);
  await checkNetwork(web3Provider!);
  await checkBlockNumber(web3Provider!);

  return signer;
};
