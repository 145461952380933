import { thunk } from 'easy-peasy';

import {
  IStoreModel,
  AuctionActionsAndThunks,
  IAuctionThunks,
  IApproveAllowancePayload,
  IMakeBidPayload,
  ICreateAuctionPayload,
} from 'types';
import { AuctionContract, TokenContract } from 'blockchain';
import appConfig from 'config/appConfig';
import auctionAPI from 'api/auction/auctionAPI';
import { Notification } from 'components';
import { NotificationTypes } from 'constants/notificationTypes';
import { getValidatedSigner } from 'utils/validateTransaction';

export const thunks: IAuctionThunks = {
  getAuctionInfo: thunk<AuctionActionsAndThunks, any, unknown, IStoreModel>(async (actions, payload) => {
    const { auctionId } = payload;
    try {
      const auctionInfo = await auctionAPI.auctionInfoAPI(auctionId);
      actions.setAuctionInfo(auctionInfo);
      actions.setError(null);
    } catch (error) {
      actions.setError(error);
    }
  }),
  getAuctionsList: thunk<AuctionActionsAndThunks, any, unknown, IStoreModel>(async (actions, payload) => {
    const { walletAddress } = payload;
    const auctionList = await auctionAPI.auctionsListAPI(walletAddress);
    actions.setAuctionList(auctionList);
  }),
  getAuctionBidsInfo: thunk<AuctionActionsAndThunks, any, unknown, IStoreModel>(async (actions, payload) => {
    const auctionBidsInfo = await auctionAPI.auctionBidsInfoAPI(payload);
    actions.setAuctionBidsInfo(auctionBidsInfo);
  }),
  createAuction: thunk<AuctionActionsAndThunks, ICreateAuctionPayload, unknown, IStoreModel>(
    async (actions, payload, { getStoreState }) => {
      const wallet = getStoreState().blockchain.wallet;
      let signer;

      if (!wallet.provider) {
        Notification({
          notificationType: NotificationTypes.Error,
          message: 'No provider',
        });
        return;
      }

      try {
        signer = await getValidatedSigner({
          web3Provider: wallet.provider,
          isConnected: wallet.isConnected,
        });
      } catch (error) {
        const Error = error as Error;
        Notification({
          notificationType: NotificationTypes.Error,
          message: 'Transaction Validation Failed',
          description: Error.message || 'An unknown error occurred during wallet validation.',
        });
        return;
      }

      actions.setLoading(true);

      try {
        const auctionContract = new AuctionContract(signer);
        const response = await auctionContract.createAuction(
          payload.tokenAddr,
          payload.amountToSell.toString(),
          payload.startPrice.toString(),
          payload.startTime.valueOf(),
          payload.endTime.valueOf(),
        );

        if (response) {
          actions.setApproved(false);
          actions.setLoading(false);

          return response;
        }
      } catch (error) {
        actions.setLoading(false);
        actions.setApproved(false);
        throw error;
      }
    },
  ),
  makeABid: thunk<AuctionActionsAndThunks, IMakeBidPayload, unknown, IStoreModel>(
    async (actions, payload, { getStoreState }) => {
      const wallet = getStoreState().blockchain.wallet;
      actions.setLoading(true);
      if (!wallet.provider) {
        Notification({
          notificationType: NotificationTypes.Error,
          message: 'No provider',
        });
        return;
      }

      let signer;

      try {
        signer = await getValidatedSigner({
          web3Provider: wallet.provider,
          isConnected: wallet.isConnected,
        });
      } catch (error) {
        const Error = error as Error;
        Notification({
          notificationType: NotificationTypes.Error,
          message: 'Transaction Validation Failed',
          description: Error.message || 'An unknown error occurred during wallet validation.',
        });
        return;
      }

      try {
        const contract = new AuctionContract(signer);
        const response = await contract.makeBid(
          payload.auctionStarter,
          payload.auctionId,
          payload.amountToBuy.toString(),
          payload.ccToBid.toString(),
        );
        if (response) {
          actions.setLoading(false);
        }
        return response;
      } finally {
        actions.setLoading(false);
      }
    },
  ),
  approveAllowance: thunk<AuctionActionsAndThunks, IApproveAllowancePayload, unknown, IStoreModel>(
    async (actions, payload, { getStoreState }) => {
      const wallet = getStoreState().blockchain.wallet;
      actions.setLoading(true);
      if (!wallet.provider) {
        Notification({
          notificationType: NotificationTypes.Error,
          message: 'No provider',
        });
        return;
      }

      let signer;

      try {
        signer = await getValidatedSigner({
          web3Provider: wallet.provider,
          isConnected: wallet.isConnected,
        });
      } catch (error) {
        const Error = error as Error;
        Notification({
          notificationType: NotificationTypes.Error,
          message: 'Transaction Validation Failed',
          description: Error.message || 'An unknown error occurred during wallet validation.',
        });
        return;
      }
      try {
        const contract = new TokenContract(payload.tokenAddr, signer);

        const approveAllowanceResponse = await contract.approve(
          appConfig.contracts.Auction.address,
          payload.amountToSell.toString(),
        );

        await approveAllowanceResponse.wait();
        actions.setLoading(false);
        actions.setApproved(true);
      } catch (error) {
        actions.setLoading(false);
        actions.setApproved(false);
        throw error;
      }
    },
  ),
  isAllowanceAuction: thunk<AuctionActionsAndThunks, string, unknown, IStoreModel>(
    async (actions, payload, { getStoreState }) => {
      const wallet = getStoreState().blockchain.wallet;

      if (!wallet.walletAddress) {
        return null;
      }

      const contract = new TokenContract(payload);
      const allowance = await contract.allowance(wallet.walletAddress, appConfig.contracts.Auction.address);
      const balance = await contract.balanceOf(wallet.walletAddress);

      return { allowance, balance };
    },
  ),
  endAuction: thunk<AuctionActionsAndThunks, string, string, IStoreModel>(
    async (actions, payload, { getStoreState }) => {
      const wallet = getStoreState().blockchain.wallet;
      if (!wallet.provider) {
        Notification({
          notificationType: NotificationTypes.Error,
          message: 'No provider',
        });
        return;
      }

      let signer;

      try {
        signer = await getValidatedSigner({
          web3Provider: wallet.provider,
          isConnected: wallet.isConnected,
        });
      } catch (error) {
        const Error = error as Error;
        Notification({
          notificationType: NotificationTypes.Error,
          message: 'Transaction Validation Failed',
          description: Error.message || 'An unknown error occurred during wallet validation.',
        });
        return;
      }
      actions.setLoading(true);

      try {
        const contract = new AuctionContract(signer);
        const response = await contract.endAuction(payload);
        if (response) {
          actions.setLoading(false);
        }

        return response;
      } finally {
        actions.setLoading(false);
      }
    },
  ),
};
